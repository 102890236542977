.navbar{
    .logo{
        width: 20%;
    }
    ul{
        li{
            a{
                font-size: 1.2rem;
            }
        }
    }
}