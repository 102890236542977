.home-wrapper{
    width: 80%;
    margin: 4% 2% 2% 2%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .pres{
        width: 40%;
        padding: 4% 10% 0 0;

        h1{
            color: $red;
            font-size: 3.5rem;
            font-family: "Nunito-Bold";    
        }

        p{  
            color : $nightblue;
            font-size: 1rem;
            font-family: "Nunito-bold";
            line-height: 1.5rem;
        }
    }
   
    .illustration{
        width: 45%;  
        
        img{
            width: 100%;
        }
    }    
}

.alert-wrapper{
    width: 80%;
    margin: 2% 0;

    display:flex;
    align-items: stretch;
    justify-content: space-around;
    flex-direction: column;   
}    

.event-wrapper{
    width: 80%;
    margin: 2% 2% 6% 2%;

    display:flex;
    align-items: flex-start;
    flex-direction: column;

    .title{ 
        width: 100% ;

        display: flex;
        flex-direction: column;

        h2{    
            color: $nightblue;
            font-size: 2.8rem;
            font-family: "Nunito-Regular"; 
        }
    
        .underline{
            height: 5px;
            width: 100%;
            background: linear-gradient(to right, $red, $yellow);
            border-radius: 5px;
        }
    } 

    .event{
        width: 100%;
        margin: 5% 0;

        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .ev{
            width: 30%;
            padding: 3px; 
            background:linear-gradient(to right, $red, $yellow);

            display: flex;
            
            .date{
                width: 20%;
                background-color: $red;

                display: flex;
                
                p{
                    color: $white;
                    font-size: 2.8rem;
                    font-family: "Nunito-Bold";
                    text-align: center;
                }
            }

            .img{
                width: 80%;
                background-color: $white;

                display: flex;

            }
        
        }
    }

    .link{
        width: 100%;
        padding: 1.5% 0;
        background-color: $red;

        display: flex;
        justify-content: center;

        a{
            text-decoration: none;
            color: $white;
            font-size: 2.3rem;
            font-family: "Nunito-Regular";
        }
    }

}

.form-wrapper{
    width: 100%;
    padding: 6% 0 6% 0;
    background-color: $nightblue;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    .title{ 
        width: 80% ;

        display: flex;
        flex-direction: column;

        h2{    
            color: $white;
            font-size: 2.8rem;
            font-family: "Nunito-Regular";     
        }
    
        .underline{
            margin: 0 0 5% 0;
            height: 5px;
            width: 100%;
            background: linear-gradient(to right, $red, $yellow);
            border-radius: 5px;
        }        
    }
    
    .contact-form{
      width: 80%;
      
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;

        form{
            width: 100%;

            display: flex;
            align-items: center;
            flex-direction: column;
            
            .fullname-row{
                width: 100%;
                margin: 0 0 2% 0;
        
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                justify-content:flex-start;
        
                .name, .surname, .email{
                    width: 33%;
                    margin: 0 2% 0 0;
                    padding: 3px; 
                    background:linear-gradient(to right, $red, $yellow);
                    border-radius: 10px;
                    
                    display: flex;
                    align-items: flex-start;
                    
                    input{
                        width: 100%;
                        padding: 2%;
                        color: white;
                        background-color: $nightblue;  
                        border: none;
                        border-radius: 10px;
                        font-size: 1.5rem;
                        font-family: "Nunito-Regular";

                        &:focus{
                            outline: none;
                        }
                        
                    }   
                }
                .email{
                    margin: 0;
                }
            }
      
            .object, .message{
                width: 100%;
                margin: 0 0 2% 0;
                padding: 3px; 
                background:linear-gradient(to right, $red, $yellow);
                border-radius: 10px;
          
                display: flex;     
      
                input, textarea{
                    width: 100%;
                    padding: 0.5%;
                    border: none;
                    border-radius: 10px;
                    color: $white;
                    background-color: $nightblue;
                    font-size: 1.5rem;
                    font-family: "Nunito-Regular";

                    &:focus{
                        outline: none;
                    }
                }
            }
            
            .send{
                width: 5%;
                padding: 2px; 
                background:linear-gradient(to right, $red, $yellow);
                border-radius: 100%;
                
                display: flex;
                
                button{
                    width: 100%;
                    padding: 25%;
                    background:$nightblue;
                    border: none;
                    border-radius: 100%;
                    cursor: pointer;
                    
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    .icon > img{
                        width: 100%;
                    }                      
                }
            }
        }
    }
}

