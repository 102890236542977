.footer-wrapper{
    width: 100%;
    height: 100%;
    padding: 1% 0 0 0;
    background-color: black;

    display: flex;
    align-items: center;
    align-self: flex-end;

    footer{
        width: 100%;
        display: flex;
        align-items: center;

        p{
            width: 100%;

            text-align: center;
            color: $white;
            font-size: 1.1rem;
            font-family: "Nunito-Regular";

        }
    }
}