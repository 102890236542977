@import "normalize.css";

// import de la police
@font-face {
    font-family: "Nunito-Regular";
    src: url('../fonts/Nunito/Nunito-Regular.ttf');
  }
  @font-face {
    font-family: "Nunito-Bold";
    src: url('../fonts/Nunito/Nunito-Bold.ttf');
  }
  
  // définition des variables couleurs
  $white:#EDEDED;
  $greylight:#CCCCCC;
  $grey:#646464;
  $greydark:#4C4C4C;
  $nightblue:#000A0C;
  $yellow:#E1EEC3;
  $red:#F05053;
  $redalert: #EF9A9A;
  $yellowalert: #FFCC80;
  $bluealert: #90CAF9;
  $greenalert: #A5D6A7;
  $semiwhite : #F9F9F9;
  
  // définition des variables d'écrans
  $sdevices:768px;
  $sdevicesmax:991px;
  $mdevices:992px;
  $mdevicesmax:1919px;
  $ldevices:1920px;

  // normalization
  h1, h2, h3{
    margin: 0;
    padding: 0;

  }

