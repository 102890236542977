.home-wrapper{
    .pres{
        h1{
            font-size: 2rem;
        }
    }
}
.event-wrapper{
    .event{
        .ev{
            .date{
                p{
                    font-size: 2rem;
                }
            }
        }
    }
    .link{
        a{
            font-size: 2rem;
        }
    }
}