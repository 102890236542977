.vg-wrapper {
  width: 80%;
  margin: 4% 2% 0 2%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .alert-wrapper{
    width: 100%;
  }

  .link{
    width: 100%;
    padding: 1.5% 0;
    background-color: $red;

    display: flex;
    justify-content: center;

    a{
        text-decoration: none;
        color: $white;
        font-size: 2.3rem;
        font-family: "Nunito-Regular";
    }
}

  .title {
    width: 100%;

    display: flex;
    flex-direction: column;

    h1 {
      color: $nightblue;
      font-size: 2.3rem;
      font-family: "Nunito-Regular";
    }

    .underline {
      margin: 0 0 5% 0;
      height: 5px;
      width: 100%;
      background: linear-gradient(to right, $red, $yellow);
      border-radius: 5px;

      display: flex;
      align-self: center;
    }
  }

  .form-part {
    width: 90%;
    align-self: center;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .subcribe-form {
      width: 60%;
      height: 100%;
      margin: 0 2% 0 0;
      padding: 0 0 2% 0;
      //background-color: $semiwhite;

      display: flex;
      flex-direction: column;
      align-items: center;

      form {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .color {
          width: 100%;
          margin: 0 0 4% 0;
          padding: 2% 0;
          background-color: $semiwhite;

          display: flex;
          flex-direction: inherit;
          justify-content: inherit;
          align-items: inherit;

          select {
            width: 40%;
            padding: 0.5% 0.5% 0.5% 0.5%;
            border-radius: 5px;
            border: 3.5px solid $nightblue;
            background: white;
          }

          .metres {
            width: 41%;

            display: flex;
            flex-direction: inherit;
            justify-content: inherit;
            align-items: flex-start;

            p {
              margin: 0;
              color: $nightblue;
              font-size: 0.9rem;
              font-family: "Nunito-Regular";
            }
          }

          p {
            color: $nightblue;
            font-size: 1.1rem;
            font-family: "Nunito-Regular";
          }

          input {
            width: 40%;
            padding: 0.5% 0.5% 0.5% 0.5%;
            border-radius: 5px;
            border: 3.5px solid $nightblue;
            font-size: 1.1rem;
            font-family: "Nunito-Regular";
          }

          textarea {
            width: 40%;
            padding: 0.5% 0.5% 0.5% 0.5%;
            resize: none;

            border-radius: 5px;
            border: 3.5px solid $nightblue;
            color: $nightblue;
            font-size: 1.1rem;
            font-family: "Nunito-Regular";
          }

          button {
            width: 100%;
            height: 10%;
            margin: 2% 0 0 0;
            background-color: $red;
            color: $white;
            font-size: 2.5rem;
            font-family: "Nunito-Regular";
          }

          .errors {
            ul {
              li {
                color: $redalert;
                list-style: symbols("*");
              }
            }
          }
        }
      }
    }

    .images {
      width: 40%;

      display: flex;
      flex-direction: column;
      align-items: center;

      .plan,
      .photo {
        width: 100%;
        margin: 0% 0 4% 0;

        display: flex;

        img {
          width: 100%;
        }
      }
    }
  }
}
