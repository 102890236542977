.event2-wrapper{
    .event{
        .ev{
            .date{
                p{
                    font-size: 2rem;
                }
            }
        }
    }
}