.navbar{
    width: 80%;
    height: 145px;
    border-bottom: 2px solid $greylight;
    padding: 1% 2% 1% 2%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .logo{
        width: 15%;
        img{
            width: 100%;
        }
    }

    ul{
        width: 70%;
        list-style: none;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        
        li {
            margin: 0 4% 0 0%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &:last-child{
                margin:0;
            }

            a{
                text-decoration: none;
                color: $nightblue;
                font-size: 1.5rem;
                font-family: "Nunito-Bold";

                        
            }

            &:hover > .underline{
                width: 100%;
                visibility: visible;
            }

            .underline{
                height: 5px;
                width: 5px;
                background: linear-gradient(to right, $red, $yellow);
                border-radius: 5px;
                transition: width .1s ease-in;
                visibility: hidden;
                align-self: center;
            }
        }

    }
}