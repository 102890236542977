.event2-wrapper{
    width: 80%;
    padding: 4% 2% 0 2%;
    margin-bottom: 2%;

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title{ 
        width: 100% ;

        display: flex;
        flex-direction: column;

        h2{    
            color: $nightblue;
            font-size: 2.8rem;
            font-family: "Nunito-Regular"; 
    
        }
    
        .underline{
            margin: 0 0 5% 0;
            height: 5px;
            width: 100%;
            background: linear-gradient(to right, $red, $yellow);
            border-radius: 5px;

            display: flex;
            align-self: center;
        }



    } 
    .event{
        width: 100%;
        padding-bottom: 2%;

        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .ev{
            width: 30%;
            margin: 0 0 6% 0;
            padding: 3px; 
            background:linear-gradient(to right, $red, $yellow);

            display: flex;
            
            .date{
                width: 20%;
                background-color: $red;

                display: flex;
                
                p{
                    color: $white;
                    font-size: 2.8rem;
                    font-family: "Nunito-Bold";
                    text-align: center;

                }
            }

            .img{
                width: 80%;
                background-color: $white;

                display: flex;

            }
        
        }
    }
}
.footer2-wrapper{
    width: 109%;
    height: 100%;
    background-color: $nightblue;

    display: flex;
    align-items: center;
}