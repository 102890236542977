@import "init.scss";

html{
  font-size: 16px;
}

body {
    min-height: 100vh;
    background: $white;
    color: $nightblue;
    font-family: "Nunito-Regular";
    display: flex;
    justify-content: center;
}

.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .alert{
    margin: 0 0 2% 0;
    padding: 0.75%;
    border-radius: 25px;

    display: flex;
    flex-direction: row;
    align-items: center;;

    img{
        width: 2%;
        margin: 0 1% 0 0;

        align-self: flex-start;
    }
    p{
      width: 98%;
      margin: 0 1% 0 0;
    }
}
.red-alert{
    background-color: $redalert;
}

.yellow-alert{
    background-color: $yellowalert;
}

.blue-alert{
    background-color: $bluealert;
}

.green-alert{
    background-color: $greenalert;
}  

// import des fichier scss
// l
  @import "scss/l/navbar.scss";
  @import "scss/l/home.scss";
  @import "scss/l/event.scss";
  @import "scss/l/form.scss";
  @import "scss/l/footer.scss";
// m
@media only screen  and (min-width: $mdevices) and (max-width: $mdevicesmax){
  .wrapper{
    width: 100%;
  }
  @import "scss/m/navbar.scss";
  @import "scss/m/home.scss";
  @import "scss/m/event.scss";
  @import "scss/m/form.scss";
  @import "scss/m/footer.scss";
}
// s
@media only screen and (min-width: $sdevices) and (max-width: $sdevicesmax){
    .wrapper{
      width: 100%;
    }
    @import "scss/s/navbar.scss";
    @import "scss/s/home.scss";
    @import "scss/s/event.scss";
    @import "scss/s/form.scss";
    @import "scss/s/footer.scss";
  }

